// import store from '@/store/index'

export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Disposisi',
    route: 'Disposisi',
    icon: 'CheckSquareIcon',
    children: [
      {
        title: 'Tambah Disposisi',
        route: 'tambah-disposisi',
        permission: 'codeZ',

      },
      {
        title: 'Proses Disposisi',
        route: 'proses-disposisi',
      },
      {
        title: 'Disposisi Selesai',
        route: 'disposisi-selesai',
      },
    ],
  },
  {
    title: 'Perintah',
    route: 'Perintah',
    icon: 'AlertCircleIcon',
    children: [
      {
        title: 'Tambah Perintah',
        route: 'tambah-perintah',
        permission: 'codeZ',
      },
      {
        title: 'Proses Perintah',
        route: 'perintah-proses',
      },
      {
        title: 'Perintah Selesai',
        route: 'perintah-selesai',
      },
    ],
  },
  {
    title: 'Surat Keluar',
    route: 'Surat Keluar',
    icon: 'MailIcon',
    children: [
      {
        title: 'Tulis Surat',
        route: 'surat-keluar-tulis',
      },
      {
        title: 'Proses Pengajuan',
        route: 'surat-keluar-proses',
      },
      {
        title: 'Daftar Surat Keluar',
        route: 'surat-keluar-daftar',
      },
    ],
  },

  {
    title: 'Permintaan Data',
    route: 'Permintaan Data',
    icon: 'FileIcon',
    children: [
      {
        title: 'Pengajuan',
        route: 'permintaan-data-pengajuan',
      },
      {
        title: 'Daftar Permintaan',
        route: 'daftar-permintaan-data',
      },
    ],
  },
  {
    title: 'Keluhan',
    route: 'Permintaan Data',
    icon: 'FeatherIcon',
    children: [
      {
        title: 'Tambah Keluhan',
        route: 'tambah-keluhan',
      },
      {
        title: 'Daftar Keluhan',
        route: 'daftar-keluhan',
      },
      // {
      //   title: 'Keluhan Selesai',
      //   route: 'dashboard-analytics',
      // },
      // {
      //   title: 'Konflik',
      //   route: 'dashboard-ecommerce',
      // },
      // {
      //   title: 'Konflik Selesai',
      //   route: 'dashboard-analytics',
      // },
    ],
  },
  {
    title: 'Arsip',
    route: 'arsip',
    icon: 'SaveIcon',
    children: [
      {
        title: 'Tambah Arsip',
        route: 'tambah-arsip',
      },
      // {
      //   title: 'Daftar Arsip',
      //   route: 'arsip-surat-masuk',
      // },
      {
        title: 'Surat Masuk',
        route: 'surat-masuk',
      },
      {
        title: 'Perintah',
        route: 'perintah',
      },
      {
        title: 'Surat Keluar',
        route: 'surat-keluar',
      },

      {
        title: 'Perjanjian Bisnis',
        route: 'perjanjian-bisnis',
      },
      {
        title: 'Perjanjian Organisasi',
        route: 'perjanjian-organisasi',
      },
      {
        title: 'Surat Tanah',
        route: 'surat-tanah',
      },
      {
        title: 'Surat Kendaraan',
        route: 'surat-kendaraan',
      },
      {
        title: 'SK Pengurus',
        route: 'sk-pengurus',
      },
      {
        title: 'SK Manajemen',
        route: 'sk-manajemen',
      },
      {
        title: 'Berita Acara',
        route: 'berita-acara',
      },
      {
        title: 'Surat Legal',
        route: 'surat-legal',
      },
      {
        title: 'Sertifikasi',
        route: 'sertifikasi',
      },
      {
        title: 'Sertifikat Penghargaan',
        route: 'sertifikat-penghargaan',
      },
    ],
  },
  {
    title: 'Security',
    route: 'security',
    icon: 'LockIcon',
    children: [
      {
        title: 'Tambah Kejadian',
        route: 'tambah-kejadian',
      },
      {
        title: 'Daftar Kejadian',
        route: 'daftar-kejadian',
      },
    ],
  },
  {
    title: 'Agenda',
    route: 'agenda',
    icon: 'CalendarIcon',
  },

  {
    title: 'Log Kendaraan',
    route: 'kendaraan',
    icon: 'TruckIcon',
    children: [
      {
        title: 'Tambah Log Kendaraan',
        route: 'tambah-kendaraan',
      },
      {
        title: 'Daftar Log Kendaraan',
        route: 'daftar-kendaraan',
      },
    ],
  },

  {
    title: 'Kejadian Penting',
    route: 'kejadian',
    icon: 'BookmarkIcon',
    children: [
      {
        title: 'Tambah Kejadian Penting',
        route: 'tambah-kejadian-penting',
      },
      {
        title: 'Daftar Kejadian Penting',
        route: 'daftar-kejadian-penting',
      },
    ],
  },

  {
    title: 'Tanggap Darurat',
    route: 'tanggap-darurat',
    icon: 'ZapIcon',
    children: [
      {
        title: 'Tambah Tanggap Darurat',
        route: 'tambah-tanggap-darurat',
      },
      {
        title: 'Daftar Tanggap Darurat',
        route: 'daftar-tanggap-darurat',
      },
    ],
  },

  {
    title: 'Manajemen Akun',
    route: 'user',
    icon: 'UserIcon',
    children: [
      {
        title: 'Pengguna',
        route: 'user',
        permission: 'codeZ',
      },
      {
        title: 'Divisi',
        route: 'role',
        permission: 'codeZ',
      },
      {
        title: 'Group',
        route: 'group',
        permission: 'codeZ',
      },
    ],
  },

  // {
  //   title: 'Second Page',
  //   route: 'second-page',
  //   icon: 'FileIcon',
  // },
]
