<template>
  <li
    v-if="canViewVerticalNavMenuLink(temp)"
    v-show="authorize ? temp.permission === 'codeZ' || temp.permission !== 'codeZ' : temp.permission !== 'codeZ' "
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': temp.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <feather-icon :icon="temp.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(temp.title) }} </span>
      <b-badge
        v-if="temp.tag"
        pill
        :variant="temp.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ temp.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store/index'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      authorize: false,
      temp: {},
      permission: store.state.userData.data.is_admin,
    }
  },
  async created() {
    // if (this.permission.find(e => e === 'SIAP.Disposition.Code.Z')) {
    //   this.authorize = true
    //   this.temp = await this.item
    // } else {
    //   this.authorize = false
    //   this.temp = await this.item
    // }
    if (this.permission) {
      this.authorize = true
      this.temp = await this.item
    } else {
      this.authorize = false
      this.temp = await this.item
    }
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item,
    )
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
}
</script>
